import Bread from '@components/common/Bread'
import { Lari, ProductCardImagePlaceholder } from '@components/ui/Icon/Icon'
import { getBrokenImage } from '@utils/getBrokenImage'
import { ListNode } from '@utils/listToTree'
import CategoryContext from 'contexts/CategoryContext'
import UserContext from 'contexts/UserContext'
import { UseTranslate } from 'hooks/Translate'
import { useRouter } from 'next/router'
import React, {
  CSSProperties,
  FC,
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  FiCopy,
  FiFacebook,
  FiHeart,
  FiShare,
  FiShoppingCart,
  FiX,
} from 'react-icons/fi'
import cn from 'classnames'
import styles from './RecipeDetails.module.scss'
import { useUser } from '@auth0/nextjs-auth0'
import { getProductPrimaryImageUrl, getRecipeImageUrl } from '@utils/getImage'
import { ICategoryHierarchy, IRecipe } from '../../@types/Recipe'
import RequestHelper from '@utils/RequestHelper'
import { BsClock } from 'react-icons/bs'
import RecipeProductQuantity from '@components/ui/RecipeProductQuantity/RecipeProductQuantity'
import { Button, Label } from '@components/ui'
import { IngredientChangeButton } from './IngredientChangeButton'
import { shareCurrentUrlOnFb } from '@utils/FBUtils'
import ToastContext from 'contexts/ToastContext'
import { isMobile } from 'react-device-detect'
import AppContext from 'contexts/AppContext'
import tagManagerUtil from '@utils/tagManagerUtil'

function isScrollable(e) {
  if (e.scrollTopMax !== undefined) return e.scrollTopMax > 0 //All Hail Firefox and it's superior technology!

  if (e == document.scrollingElement)
    //If what you're checking is BODY (or HTML depending on your css styles)
    return e.scrollHeight > e.clientHeight //This is a special case.

  return (
    e.scrollHeight > e.clientHeight &&
    ['scroll', 'auto'].indexOf(getComputedStyle(e).overflowY) >= 0
  )
}

interface RecipeDetailsProps {
  recipeData?: IRecipe
  recipeId?: string
  lang: string
  onClose?: () => void
}

interface RecipeRequest {
  ingredientsMap: {
    [key: string]: number
  }
  totalPrice: number
  _id: string
}
export type UniCat = Category & ListNode

const RecipeDetails: FC<
  React.PropsWithChildren<
    React.PropsWithChildren<React.PropsWithChildren<RecipeDetailsProps>>
  >
> = ({ recipeId, recipeData, lang, onClose }) => {
  const router = useRouter()
  const [routes, setRoutes] = useState<BreadCrumb[]>([])
  const [translate] = UseTranslate()
  const [openedAlternativeIngredient, setOpenedAlternativeIngredient] =
    useState<string>()
  const [recipe, setRecipe] = useState<IRecipe>()
  const [detailsStyles, setDetailsStyles] = useState<string>(
    cn({
      [styles.details]: true,
      [styles.disableAfter]: false,
    })
  )
  const scrollableContainerRef = useRef(null)
  const { wishlist, loadWishlist, customerInfo, selectedAddress } =
    useContext(UserContext)
  const [recipeRequest, setRequest] = useState<RecipeRequest>()
  const { user } = useUser()
  const [isLoading, setIsLoading] = useState(false)
  const [isRecipeOutOfStock, setRecipeOutOfStock] = useState(false)

  const { loadCart } = useContext(AppContext)
  useEffect(() => {
    if (recipe) {
      setBreadData(recipe)
      initRecipeRequest(recipe)
    }

    if (user && recipe) {
      tagManagerUtil.OpenRecipePage(user['sub'], recipe._id)
    }
  }, [recipe])

  useEffect(() => {
    setTimeout(() => {
      const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } =
          scrollableContainerRef.current
        if (scrollTop + clientHeight >= scrollHeight - 5) {
          setDetailsStyles(
            cn({
              [styles.details]: true,
              [styles.disableAfter]: true,
            })
          )
        } else {
          setDetailsStyles(
            cn({
              [styles.details]: true,
              [styles.disableAfter]: false,
            })
          )
        }
      }
      if (scrollableContainerRef.current) {
        const scrollableContainer = scrollableContainerRef.current
        setDetailsStyles(
          cn({
            [styles.details]: true,
            [styles.disableAfter]: !isScrollable(scrollableContainer),
          })
        )
        scrollableContainer.addEventListener('scroll', handleScroll)
        return () => {
          scrollableContainer.removeEventListener('scroll', handleScroll)
        }
      }
    }, 1000)
  }, [])

  useEffect(() => {
    if (recipeData) {
      setRecipe(recipeData)
      setBreadData(recipeData)
      initRecipeRequest(recipeData)
    }
  }, [recipeData])

  useEffect(() => {
    if (recipeId) {
      const getRecipe = async () => {
        let url = `recipes/ecommerce/${recipeId}?lang=${lang}`
        if (user?.sub) {
          url += `&userId=${user?.sub}`
        }
        if (selectedAddress?.warehouseId) {
          url += `&warehouseId=${selectedAddress?.warehouseId}`
        }
        const response = await RequestHelper.catalog.get(url)
        setRecipe(response.data.data)
      }

      getRecipe()
    }
  }, [recipeId])

  const makeFavourite = async () => {
    if (!user || !user.sub) {
      window.open(`/api/auth/login?lang=${lang || 'ge'}`, '_self')
      return
    }
    const {
      data: { data },
    } = await RequestHelper.catalog.post(`recipes/favourite`, {
      recipeId: recipe._id,
    })
    if (data.recipes) {
      const index = data.recipes.find((s) => s == recipe._id.toString())
      if (index) {
        setRecipe({ ...recipe, isFavourited: true })
        addToast({
          id: recipe._id,
          description: translate('shop.whishlist.recipe.favoriteAdded'),
          type: 'success',
        })
      } else {
        setRecipe({ ...recipe, isFavourited: false })
        addToast({
          id: recipe._id,
          description: translate('shop.whishlist.recipe.favoriteRemoved'),
          type: 'success',
        })
      }
    }
    if (wishlist) {
      loadWishlist()
    }
  }

  const initRecipeRequest = (rec: IRecipe) => {
    const ingMap = {}
    rec.selectedIngredients.map((m) => {
      if (!ingMap[m._id]) ingMap[m._id] = m['productQuantityInRecipe']
    })

    setRequest({
      _id: rec._id,
      ingredientsMap: ingMap,
      totalPrice:
        Math.round(generateTotalPrice(rec.selectedIngredients, ingMap) * 100) /
        100,
    })

    const existsInStockProduct = rec.selectedIngredients.find(
      (a) => a.stock?.stock
    )

    if (!existsInStockProduct) setRecipeOutOfStock(true)
  }
  const setBreadData = (recipe: IRecipe) => {
    const breadRoutes: BreadCrumb[] = [
      {
        path: `/${lang}/recipes`,
        breadcrumbName: 'ecommerce.recipes',
        translate: true,
      },
    ]
    if (recipe.categoryHierarchy && recipe.categoryHierarchy.length) {
      recipe.categoryHierarchy.forEach((category: ICategoryHierarchy) => {
        breadRoutes.push({
          path: `/${lang}/recipes/${category?.nameSlug}`,
          breadcrumbName:
            Object.keys(category?.name || {}).length > 0 && category?.name[lang]
              ? category?.name[lang]
              : `no translation for ${lang}`,
          translate: false,
        })
      })
    }

    setRoutes(breadRoutes)
  }

  const generateDiscount = (ingredient: Product) => {
    return ingredient.discount?.price ||
      (ingredient.loyaltyDiscount?.isClub &&
        customerInfo?.clubMembership?.current?.isMember) ||
      (customerInfo?.cards.length > 0 &&
        ingredient.loyaltyDiscount &&
        !ingredient.loyaltyDiscount?.isClub &&
        !ingredient.loyaltyDiscount?.isSpecial) ? (
      <a>
        <span
          className={
            isMobile
              ? styles.mobile_ingedient_discount_price
              : styles.productInfo__price_discount
          }
        >
          {ingredient.stock?.price ? ingredient.stock.price.toFixed(2) : 0}
          &nbsp;
          <Lari />
        </span>
      </a>
    ) : null
  }

  const generatePrice = (product) => {
    if (
      product.loyaltyDiscount?.isClub &&
      customerInfo?.clubMembership?.current?.isMember
    ) {
      return product.loyaltyDiscount.price
    }
    if (
      product.loyaltyDiscount &&
      customerInfo?.cards.length > 0 &&
      !product.loyaltyDiscount.isSpecial &&
      !product.loyaltyDiscount.isClub
    ) {
      return product.loyaltyDiscount.price
    }
    if (product.discount) {
      return product.discount?.price
    }
    return product.stock?.price || 0
  }

  const changeIngredientQuantity = (ing: Product, q: number) => {
    const r = { ...recipeRequest }
    r['ingredientsMap'][ing._id] = q
    r['totalPrice'] =
      Math.round(
        generateTotalPrice(recipe.selectedIngredients, r['ingredientsMap']) *
          100
      ) / 100
    setRequest(r)
  }

  const changeIngredient = (prev: string, cur: Product) => {
    const r = { ...recipeRequest }
    delete r['ingredientsMap'][prev]
    r['ingredientsMap'][cur._id] = cur['productQuantityInRecipe']

    const newRec = { ...recipe }

    newRec.selectedIngredients = newRec.selectedIngredients.map((ing) => {
      if (ing._id == prev) {
        const newALternatives = []
        ing['alternatives'] = (ing['alternatives'] || []).map((alt) => {
          if (alt._id == cur._id) {
            const tmpIng = { ...ing, alternatives: [] }
            ing = { ...alt, alternatives: [] }

            alt = {
              ...tmpIng,
            }
            newALternatives.push(alt)
          } else {
            newALternatives.push(alt)
          }
          return alt
        })
        ing['alternatives'] = newALternatives
      }
      return ing
    })

    setRecipe(newRec)

    r['totalPrice'] =
      Math.round(
        generateTotalPrice(newRec.selectedIngredients, r['ingredientsMap']) *
          100
      ) / 100
    setRequest({ ...r })
  }

  const generateTotalPrice = (ingedients: Product[], r) => {
    return ingedients.reduce((a, b) => a + generatePrice(b) * r[b._id], 0)
  }

  const [shareMenuOpen, setShareMenuOpen] = useState(false)
  const { addToast } = useContext(ToastContext)
  const shareMenuClassName = cn(styles.properties__shareMenu, {
    [styles.shareMenuOpen]: shareMenuOpen,
  })

  const shareMenuBackdropClassName = cn(styles.shareMenuBackdrop, {
    [styles.shareMenuOpen]: shareMenuOpen,
  })
  const copyUrl = () => {
    showToast('success', translate('urlCopied'))
    navigator.clipboard.writeText(window.location.href)
    setShareMenuOpen(false)
  }
  const showToast = (type, description) => {
    const id = Math.floor(Math.random() * 101 + 1)
    addToast({
      id: id.toString(),
      description: description,
      type: type,
    })
  }

  const getIconColor = (): CSSProperties => {
    return recipe['isFavourited']
      ? { width: 18, height: 18, fill: '#FE5000', stroke: '#FE5000' }
      : { width: 18, height: 18, fill: '#FFFFFF', stroke: '#FE5000' }
  }

  const ShareActions = () => {
    return (
      <div className={styles.propertiesContainer}>
        <div
          className={styles.properties__share}
          onClick={() => setShareMenuOpen(!shareMenuOpen)}
        >
          <FiShare className={styles.share_svg} />
          <div className={shareMenuBackdropClassName}></div>
          <ul className={shareMenuClassName}>
            <li onClick={shareCurrentUrlOnFb}>
              <FiFacebook />
              <span style={{ marginLeft: 15 }}>
                {translate('productDetails.facebookShare')}
              </span>
            </li>
            <li onClick={copyUrl}>
              <FiCopy />
              <span style={{ marginLeft: 15 }}>
                {translate('productDetails.copy')}
              </span>
            </li>
          </ul>
        </div>
        <div
          className={styles.properties__heart}
          onClick={() => {
            makeFavourite()
          }}
        >
          <FiHeart style={getIconColor()} />
        </div>
      </div>
    )
  }

  const addToCart = async () => {
    if (!recipeRequest || !recipeRequest.ingredientsMap) return

    const ingredients = recipe.selectedIngredients.filter((a) => a.stock?.stock)
    if (!ingredients.length) return

    const productsToAdd = ingredients
      .filter((ing) => recipeRequest.ingredientsMap[ing._id])
      .map((ing) => ({
        prodId: ing.productId,
        quantity: recipeRequest.ingredientsMap[ing._id],
      }))

    if (!productsToAdd.length) {
      showToast('fail', translate('ecommerce.recipe.noProductsToAdd'))
    }

    try {
      if (!user) {
        showToast('fail', translate('ecommerce.recipe.failedToAdd'))
        return
      }
      setIsLoading(true)
      const response = await RequestHelper.catalog.post(`carts/recipe/add`, {
        products: productsToAdd,
      })
      if (response.data && response.data.data) {
        localStorage.setItem('cartId', response.data.data._id)
        loadCart(
          lang,
          customerInfo?.clubMembership?.current?.isMember,
          user.sub,
          selectedAddress?.warehouseId
        )
        showToast('success', translate('ecommerce.recipe.addProductsSuccess'))
        if (user) {
          tagManagerUtil.AddRecipeProductsToCart(
            user.sub,
            recipe._id,
            recipeRequest['totalPrice']
          )
        }
        if (onClose) onClose()
        else router.push(`/${lang}`)
      }
    } catch (error) {
      showToast('fail', translate('ecommerce.recipe.failedToAdd'))
    }
    setIsLoading(false)
  }

  return (
    <>
      {recipe && recipeRequest ? (
        <section className={styles.mbFive}>
          <div className="metad-container">
            <div className="df">
              {routes && routes.length ? <Bread routes={routes} /> : ''}
              {onClose ? (
                <div className={styles.closeBtn} onClick={() => onClose()}>
                  <FiX />
                </div>
              ) : null}
            </div>
            <div className={styles.productDetails}>
              <div className={styles.productInfo}>
                <figure className={styles.productInfo__image}>
                  {recipe?.image ? (
                    <img
                      src={getRecipeImageUrl(recipe)}
                      alt={recipe.name}
                      onError={getBrokenImage}
                    />
                  ) : (
                    <ProductCardImagePlaceholder />
                  )}
                </figure>
                <div className={detailsStyles}>
                  <div className={styles.titleCnt}>
                    <div className={styles.title}>{recipe.name}</div>
                    <ShareActions />
                  </div>
                  <div className={styles.prepareTimeCnt}>
                    <div className={styles.summery}>
                      <div className={styles.clockIconCnt}>
                        <BsClock className={styles.clockIcon} />
                      </div>
                      <div className={styles.coockingTimeCnt}>
                        <span className={styles.coockingTimeTitle}>
                          {translate('ecommerce.recipe.cookingTime')}
                        </span>

                        <span className={styles.coockingTimeValue}>
                          {recipe.cookingTime || 0}{' '}
                          {translate('ecommerce.recipe.cookingTime.minutes')}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className={styles.description}
                    ref={scrollableContainerRef}
                  >
                    <div className={styles.descriptionTitle}>
                      {translate('ecommerce.recipe.desctiption.title')}
                    </div>
                    <div
                      className={styles.html}
                      dangerouslySetInnerHTML={{ __html: recipe.description }}
                    ></div>
                  </div>
                </div>
              </div>
              <div className={styles.ingredients}>
                <div className={styles.ingredients_title}>
                  {translate('ecommerce.recipe.ingredients.title')}
                </div>
                <div className={styles.ingredients_hint}>
                  {translate('ecommerce.recipe.ingredients.hint')}
                </div>

                <div className={styles.ingredients_grid}>
                  {isMobile ? (
                    <>
                      <div className={styles.ingredients_mobile}>
                        {(recipe.selectedIngredients || []).map(
                          (ingredient, index) => (
                            <Fragment key={ingredient._id}>
                              <div className={styles.ingedientPhotoContainer}>
                                <figure className={styles.product__image}>
                                  {ingredient?.images &&
                                  ingredient.images[0] ? (
                                    <img
                                      src={getProductPrimaryImageUrl(
                                        ingredient
                                      )}
                                      alt={ingredient.title}
                                      onError={getBrokenImage}
                                    />
                                  ) : (
                                    <ProductCardImagePlaceholder />
                                  )}
                                </figure>
                                <div
                                  className={styles.mobile_ingredientDetails}
                                >
                                  <div className={styles.mobile_ingedientTitle}>
                                    {ingredient.title}
                                  </div>
                                  <div className={styles.mobile_price_cnt}>
                                    <div className={styles.mobile_price}>
                                      <span
                                        className={
                                          styles.mobile_ingedient_price
                                        }
                                      >
                                        {generatePrice(ingredient).toFixed(2)}{' '}
                                        <Lari />
                                      </span>
                                      {generateDiscount(ingredient)}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                className={styles.mobile_ingedientAmountParent}
                              >
                                <div
                                  className={styles.mobile_ingedientAmountCnt}
                                >
                                  <RecipeProductQuantity
                                    stock={ingredient.stock?.stock || 0}
                                    quantity={
                                      recipeRequest['ingredientsMap'][
                                        ingredient._id
                                      ]
                                    }
                                    readonly={!ingredient.stock?.stock}
                                    id={ingredient._id}
                                    productQuantityInRecipe={
                                      ingredient['productQuantityInRecipe']
                                    }
                                    countStep={ingredient.countStep}
                                    onChange={(q) => {
                                      changeIngredientQuantity(ingredient, q)
                                    }}
                                  />
                                </div>

                                {ingredient.stock?.stock ? (
                                  <IngredientChangeButton
                                    isLast={
                                      index ==
                                      recipe.selectedIngredients.length - 1
                                    }
                                    onChange={(cur: Product) => {
                                      changeIngredient(ingredient._id, cur)
                                    }}
                                    alternatives={ingredient['alternatives']}
                                    productQuantityInRecipe={
                                      ingredient['productQuantityInRecipe']
                                    }
                                    opened={
                                      openedAlternativeIngredient ==
                                      ingredient._id
                                    }
                                    onOpen={(e) => {
                                      setOpenedAlternativeIngredient(
                                        openedAlternativeIngredient !=
                                          ingredient._id
                                          ? ingredient._id
                                          : ''
                                      )
                                    }}
                                    onClose={() => {
                                      setOpenedAlternativeIngredient('')
                                    }}
                                  ></IngredientChangeButton>
                                ) : (
                                  <Label
                                    style={{ fontSize: 12, marginLeft: 'auto' }}
                                    color="#99AEBB"
                                    size="auto"
                                    text={translate('ecommerce.notInStock')}
                                  />
                                )}
                              </div>
                            </Fragment>
                          )
                        )}
                      </div>
                      <div className={styles.ingredients_footer_mobile}>
                        <div className={styles.mobileFooterCnt}>
                          <div className={styles.texts}>
                            {translate('ecommerce.recipe.details.totalCost')}{' '}
                            {recipeRequest['totalPrice'] || 0} <Lari />
                          </div>
                          <div className={styles.footerActions}>
                            <div className={styles.action}>
                              <Button
                                text={translate('ecommerce.recipe.addToCart')}
                                disabled={isLoading || isRecipeOutOfStock}
                                onClick={addToCart}
                                iconRight={<FiShoppingCart />}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <table className={styles.table}>
                      <thead>
                        <tr>
                          <th colSpan={7} className={styles.titleCol}>
                            {translate(
                              'ecommerce.recipe.ingredients.grid.title'
                            )}
                          </th>
                          <th></th>
                          <th style={{ textAlign: 'center' }}>
                            {translate(
                              'ecommerce.recipe.ingredients.grid.price'
                            )}
                          </th>

                          <th
                            style={{ textAlign: 'right' }}
                            className={styles.ingedientAmountParent}
                          >
                            {translate(
                              'ecommerce.recipe.ingredients.grid.amount'
                            )}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {(recipe.selectedIngredients || []).map(
                          (ingredient, index) => (
                            <Fragment key={ingredient._id}>
                              <tr>
                                <td colSpan={7} className={styles.titleCol}>
                                  <div
                                    className={styles.ingedientPhotoContainer}
                                  >
                                    <figure className={styles.product__image}>
                                      {ingredient?.images &&
                                      ingredient.images[0] ? (
                                        <img
                                          src={getProductPrimaryImageUrl(
                                            ingredient
                                          )}
                                          alt={ingredient.title}
                                          onError={getBrokenImage}
                                        />
                                      ) : (
                                        <ProductCardImagePlaceholder />
                                      )}
                                    </figure>
                                    <div className={styles.ingedientTitle}>
                                      {ingredient.title}
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  {ingredient.stock?.stock ? (
                                    <IngredientChangeButton
                                      isLast={
                                        index ==
                                        recipe.selectedIngredients.length - 1
                                      }
                                      onChange={(cur: Product) => {
                                        changeIngredient(ingredient._id, cur)
                                      }}
                                      alternatives={ingredient['alternatives']}
                                      productQuantityInRecipe={
                                        ingredient['productQuantityInRecipe']
                                      }
                                      opened={
                                        openedAlternativeIngredient ==
                                        ingredient._id
                                      }
                                      onOpen={(e) => {
                                        setOpenedAlternativeIngredient(
                                          openedAlternativeIngredient !=
                                            ingredient._id
                                            ? ingredient._id
                                            : ''
                                        )
                                      }}
                                      onClose={() => {
                                        setOpenedAlternativeIngredient('')
                                      }}
                                    ></IngredientChangeButton>
                                  ) : (
                                    <Label
                                      style={{
                                        fontSize: 12,
                                        marginLeft: 'auto',
                                      }}
                                      color="#99AEBB"
                                      size="auto"
                                      text={translate('ecommerce.notInStock')}
                                    />
                                  )}
                                </td>
                                <td className={styles.ingedientPriceCnt}>
                                  <span className={styles.ingedientPrice}>
                                    {generatePrice(ingredient).toFixed(2)}{' '}
                                    <Lari />
                                  </span>
                                  {generateDiscount(ingredient)}
                                </td>
                                <td className={styles.ingedientAmountParent}>
                                  <div className={styles.ingedientAmountCnt}>
                                    <RecipeProductQuantity
                                      stock={ingredient.stock?.stock || 0}
                                      quantity={
                                        recipeRequest['ingredientsMap'][
                                          ingredient._id
                                        ]
                                      }
                                      readonly={!ingredient.stock?.stock}
                                      id={ingredient._id}
                                      productQuantityInRecipe={
                                        ingredient['productQuantityInRecipe']
                                      }
                                      countStep={ingredient.countStep}
                                      onChange={(q) => {
                                        changeIngredientQuantity(ingredient, q)
                                      }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            </Fragment>
                          )
                        )}
                        {recipeRequest && (
                          <Fragment key={'tbl-footer'}>
                            <tr className={styles.tableFooter}>
                              <td colSpan={10}>
                                <div className={styles.footerCnt}>
                                  <div className={styles.texts}>
                                    {translate(
                                      'ecommerce.recipe.details.totalCost'
                                    )}{' '}
                                    {recipeRequest['totalPrice'] || 0} <Lari />
                                  </div>
                                  <div className={styles.footerActions}>
                                    <div className={styles.texts}>
                                      {translate(
                                        'ecommerce.recipe.details.action.hint'
                                      )}
                                    </div>
                                    <div className={styles.action}>
                                      <Button
                                        text={translate(
                                          'ecommerce.recipe.addToCart'
                                        )}
                                        disabled={
                                          isLoading || isRecipeOutOfStock
                                        }
                                        onClick={addToCart}
                                        iconRight={<FiShoppingCart />}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </Fragment>
                        )}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : null}
    </>
  )
}

export default RecipeDetails
