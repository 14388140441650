const tagManagerUtil = {
  _push(args: any) {
    window['dataLayer'] = window['dataLayer'] || []
    window['dataLayer'].push(args)
  },

  /**
   * ნებისმიერი პროდუქტის გახსნისას - შოპის პროდუქტის full screen გვერდიდან და მოდალიდანაც (Quick View) უნდა იგზავნებოდეს ეს ივენთი.
   */
  ProductView(args: {
    ProductName: string
    ProductPrice: number
    ProductCategory: string // არა ID
    ProductDescription: string // striptags
    ProductID: string
    UserID: string // ცარიელი როცა არ არის დალოგინებული
    categoryLevels: Record<string, string>
  }): void {
    tagManagerUtil._push({
      event: 'view_item',
      ecommerce: {
        items: [
          {
            item_name: args.ProductName,
            item_id: args.ProductID,
            price: args.ProductPrice,
            currency: 'GEL',
            ...args.categoryLevels,
          },
        ],
      },
    })
  },

  /**
   * რეგისტრაციის დასრულებისას
   */
  Registration(args: { UserID: string; PhoneNumber: string }): void {
    tagManagerUtil._push({
      event: 'Registration',
      ...args,
    })
  },

  /** პროფილის დაედიტებისას */
  EditProfile(args: {
    UserName: string
    UserSurname: string
    UserEmail: string
    UserID: string
    PhoneNumber: string
  }): void {
    tagManagerUtil._push({
      event: 'EditProfile',
      ...args,
    })
  },

  /**
   * მისამართის დამატებისას, როგორც პროფილიდან ისევე შეკვეთიდან
   */
  AddAdress(args: {
    UserAdress: string
    UserID: string
    AdressName: string
    CartItems: CartProduct[] // პროდუქტის დასახელების სია
  }): void {
    tagManagerUtil._push({
      event: 'add_shipping_info',
      shipping_type: 'Delivery',
      items: (args.CartItems || []).map((e) => {
        return {
          item_name: e.title,
          item_id: e.prodId,
          price: e.price,
          currency: 'GEL',
          quantity: e.quantity,
          ...e.categoryLevels,
        }
      }),
    })
  },

  /**
   * პროდუქტის კარტში დამატებისას
   */
  AddtoCart(args: {
    ProductName: string
    ProductID: string
    UserID: string
    ProductPrice: number
    ProductCategory: string
    Quantity: number
    categoryLevels: Record<string, string>
  }): void {
    tagManagerUtil._push({
      event: 'add_to_cart',
      ecommerce: {
        items: [
          {
            item_name: args.ProductName,
            item_id: args.ProductID,
            price: args.ProductPrice,
            currency: 'GEL',
            quantity: args.Quantity,
            ...args.categoryLevels,
          },
        ],
      },
    })
  },

  /**
   * პროდუქტის კალათიდან ამოშლისას
   */
  RemoveFromCart(args: {
    ProductName: string
    ProductID: string
    UserID: string
    ProductPrice: number
    ProductCategory: string
    Quantity: number
    categoryLevels: Record<string, string>
  }): void {
    tagManagerUtil._push({
      event: 'remove_from_cart',
      ecommerce: {
        items: [
          {
            item_name: args.ProductName,
            item_id: args.ProductID,
            price: args.ProductPrice,
            currency: 'GEL',
            quantity: args.Quantity,
            ...args.categoryLevels,
          },
        ],
      },
    })
  },

  RemoveAllFromCart(args: CartProduct[]): void {
    tagManagerUtil._push({
      event: 'remove_from_cart',
      ecommerce: {
        items: args.map((e) => {
          return {
            item_name: e.title,
            item_id: e.productId || e.prodId,
            price: e.price,
            currency: 'GEL',
            quantity: e.quantity,
            ...e.categoryLevels,
          }
        }),
      },
    })
  },

  AddPaymentInfo(args: {
    UserID: string
    CartItems: CartProduct[] // პროდუქტის დასახელების სია
  }) {
    tagManagerUtil._push({
      event: 'add_payment_info',
      ecommerce: {
        items: (args.CartItems || []).map((e) => {
          return {
            item_name: e.title,
            item_id: e.prodId,
            price: e.price,
            currency: 'GEL',
            quantity: e.quantity,
            ...e.categoryLevels,
          }
        }),
      },
    })
  },

  /**
   * შეკვეთის დაწყება
   */
  StartPurchase(args: {
    UserID: string
    CartItems: CartProduct[] // პროდუქტის დასახელების სია
  }): void {
    tagManagerUtil._push({
      event: 'begin_checkout',
      ecommerce: {
        items: (args.CartItems || []).map((e) => {
          return {
            item_name: e.title,
            item_id: e.prodId,
            price: e.price,
            currency: 'GEL',
            quantity: e.quantity,
            ...e.categoryLevels,
          }
        }),
      },
    })
  },

  /**
   * გადახდა და ყიდვაზე დაკლიკებისას წარმატებული გადახდის შემდეგ უნდა გაიგზავნოს ივენთი.
   */
  Purchase(args: {
    UserID: string
    TotalSum: number
    TotalAmount: number
    CartItems: OrderProduct[] // პროდუქტის დასახელების სია
    OrderNumber: string
    DiscountAmount: number
    SurchargeAmount: number
    DeliveryPrice: number
    VoucherCode: string
    OrderId: string
  }): void {
    tagManagerUtil._push({
      event: 'Purchase',
      ecommerce: {
        transaction_id: args.OrderNumber,
        value: args.TotalSum,
        currency: 'GEL',
        shipping_type: 'Delivery',
        discount: args.DiscountAmount,
        service_cost: args.SurchargeAmount,
        shipping: args.DeliveryPrice,
        coupon: args.VoucherCode,
        tax: 0,
        items: (args.CartItems || []).map((e) => {
          return {
            item_name: e.title,
            item_id: e.prodId,
            price: e.price,
            currency: 'GEL',
            quantity: e.quantity,
            ...e.categoryLevels,
          }
        }),
      },
    })
    if (args.VoucherCode) {
      tagManagerUtil._push({
        event: 'referral_use',
        user_id: args.UserID,
        promo_code: args.VoucherCode,
        order_id: args.OrderId,
      })
    }
  },

  PurchaseAfterPayment(order: Order): void {
    if (order) {
      tagManagerUtil._push({
        ecommerce: {
          purchase: {
            actionField: {
              id: order._id,
              revenue: order.shouldBePayed,
              shipping: order.deliveryPrice,
              coupon: order.voucherCode,
            },
            products: order.products.map((e) => {
              return {
                name: e.title,
                id: e.prodId,
                price: e.price,
                quantity: e.quantity,
              }
            }),
          },
        },
      })
      if (order.voucherCode) {
        tagManagerUtil._push({
          event: 'referral_use',
          user_id: order.user.userId,
          promo_code: order.voucherCode,
          order_id: order._id,
        })
      }
    }
  },

  ReferallCopy(code: string, userId: string) {
    tagManagerUtil._push({
      event: 'referral_copy',
      user_id: userId,
      promo_code: code,
    })
  },

  SeeFavorites() {
    tagManagerUtil._push({ event: 'see_favorites' })
  },

  SeeCart() {
    tagManagerUtil._push({ event: 'see_cart' })
  },

  SeeLoyaltyCard() {
    tagManagerUtil._push({ event: 'see_loyalty_card' })
  },

  StartChat() {
    tagManagerUtil._push({ event: 'start_chat' })
  },

  SavePlus() {
    tagManagerUtil._push({ event: 'save_plus' })
  },

  SavePlusContinue() {
    tagManagerUtil._push({ event: 'save_plus_continue' })
  },

  SavePlusActivate() {
    tagManagerUtil._push({ event: 'save_plus_activate' })
  },

  GoToRecipePage(user_id: string) {
    tagManagerUtil._push({ event: 'go_to_recipe_page', user_id })
  },

  OpenRecipePage(user_id: string, recipe_id: string) {
    tagManagerUtil._push({ event: 'open_specific_recipe', user_id, recipe_id })
  },

  AddRecipeProductsToCart(
    user_id: string,
    recipe_id: string,
    total_cost: number
  ) {
    tagManagerUtil._push({
      event: 'add_recipe_products_to_cart',
      user_id,
      recipe_id,
      total_cost,
    })
  },
}

export default tagManagerUtil
