import React, { useContext, useEffect, useState } from 'react'
import cn from 'classnames'

import styles from './CartPopup.module.scss'

import { UseTranslate } from 'hooks/Translate'
import { useLocalStorage } from 'hooks/LocalStorage'

import CartPopupItem from './CartPopupItem'
import AppContext from 'contexts/AppContext'
import { Lari, ProductCardImagePlaceholder } from '@components/ui/Icon/Icon'
import { useRouter } from 'next/router'
import { FiLoader, FiPlus, FiShoppingCart, FiX } from 'react-icons/fi'
import { hasScrollbar, paddingInlineEnd } from '@utils/padding-inline-end'
import { useUser } from '@auth0/nextjs-auth0'
import Backdrop from '@components/ui/Backdrop/Backdrop'
import RequestHelper from '@utils/RequestHelper'
import UserContext from 'contexts/UserContext'
import { Modal } from '@components/ui'
import Button from '@components/ui/Button/Button'
import tagManagerUtil from '@utils/tagManagerUtil'
import CategoryContext from 'contexts/CategoryContext'
import { UniCat } from '@components/ProductDetails/ProductDetails'
import { getCategoryLevels } from '@utils/categoryUtils'
import { getProductPrimaryImageUrl } from '@utils/getImage'
import { getBrokenImage } from '@utils/getBrokenImage'
import ToastContext from 'contexts/ToastContext'
import { logBrazeEventCleanCart } from '@utils/BrazeDefaultUserDetails'

export default function CartPopup() {
  const [products, setProducts] = useState<CartProduct[]>([])
  const [showModal, setShowModal] = useState(false)
  const router = useRouter()
  let { lang } = router.query
  if (!lang) {
    lang = 'ge'
  }
  const {
    selectedLanguage,
    cartTotals,
    openCart,
    setOpenCart,
    cart,
    loadCart,
    setOpenSpecialDiscount,
    addProductToCart,
    isInactiveAddress,
    deliveryServiceConfig,
    setShowInactiveAddressModal,
  } = useContext(AppContext)
  const { selectedAddress, customerInfo, accumulatedPoints } =
    useContext(UserContext)
  const { categoriesData } = useContext(CategoryContext)
  const [cartId] = useLocalStorage('cartId', '')
  const [translate] = UseTranslate()
  const [itemLoading, setItemLoading] = useState(false)
  const [categories, setCategories] = useState<UniCat[]>([])
  const { user } = useUser()
  const [cartBtn, setCartBtn] = useState(
    cn({
      [styles.cartPopup__footer__button]: true,
      [styles.cartPopup__footer__button_disabled]: !cartTotals.quantity,
    })
  )
  const { addToast } = useContext(ToastContext)
  const [allPopularProducts, setAllPopularProducts] = useState<CartProduct[]>(
    []
  )
  const [popularProducts, setPopularProducts] = useState<CartProduct[]>([])

  useEffect(() => {
    setCategories(categoriesData as UniCat[])
  }, [categoriesData])

  useEffect(() => {
    fetchCart()
    if (cart?.products.length && allPopularProducts.length == 0) {
      getPopularProducts(selectedAddress?.warehouseId)
    }
  }, [cart])

  useEffect(() => {
    if (cart?._id) {
      getPopularProducts(selectedAddress?.warehouseId)
    }
  }, [selectedAddress])

  useEffect(() => {
    const scroll = hasScrollbar()
    document.body.style.overflow = openCart ? 'hidden' : 'auto'
    paddingInlineEnd(openCart, scroll)
    setShowModal(false)
  }, [openCart])

  const cartPopupClassName = cn({
    [styles.cartPopup]: true,
    [styles.showCartPopup]: openCart,
    [styles.cartPopup__mini_cart]: !openCart,
  })

  async function fetchCart() {
    if (cart) {
      setProducts(cart.products)
    } else {
      setProducts([])
    }
    let notInStockCount = 0
    ;(cart?.products || []).forEach((product) => {
      if (!product.inStock && product.maxAvailable <= 0) {
        notInStockCount++
      }
    })
    if (notInStockCount) {
      setCartBtn(
        cn({
          [styles.cartPopup__footer__button]: true,
          [styles.cartPopup__footer__button_disabled]: true,
        })
      )
    } else {
      setCartBtn(
        cn({
          [styles.cartPopup__footer__button]: true,
          [styles.cartPopup__footer__button_disabled]: !cartTotals.quantity,
        })
      )
    }
    tagManagerUtil.SeeCart()
  }

  const goToCheckoutPage = () => {
    if (!selectedAddress) {
      addToast({
        id: 'addresChoose',
        type: 'fail',
        description: translate('chooseAddressBeforeCheckout'),
        dismissTime: 4000,
      })
      return
    }
    setOpenCart()

    for (const { loyaltyDiscount } of cart.products) {
      if (
        loyaltyDiscount?.isSpecial &&
        accumulatedPoints >= loyaltyDiscount?.discountPriceInPoints
      ) {
        return setOpenSpecialDiscount()
      }
    }

    router.push(`/${lang}/checkout`)
  }

  const checkout = async () => {
    let notInStockCount = 0
    ;(products || []).forEach((product) => {
      if (!product.inStock && product.maxAvailable <= 0) {
        notInStockCount++
      }
    })
    if (notInStockCount) {
      return
    }
    if (!cartTotals.quantity) {
      return
    }
    if (!user) {
      window.open(
        `/api/auth/login?lang=${selectedLanguage.key || 'ge'}`,
        '_self'
      )
    } else {
      goToCheckoutPage()
    }
  }

  const getPopularProducts = async (warehouseId?: string) => {
    const res = await RequestHelper.catalog.get(
      `/products/related/random?warehouseId=${warehouseId || ''}&cartId=${
        cart?._id
      }&onlyInStockProducts=true`
    )
    if (res && res.data) {
      const popularProducts = res.data.data.products
      setAllPopularProducts([...popularProducts])
    }
  }

  const navigateToDetails = (prod) => {
    setOpenCart()
    router.push(`/${lang}/product/${prod.nameSlug}`)
  }

  const generatePrice = (product) => {
    if (
      product?.loyaltyDiscount?.isClub &&
      customerInfo?.clubMembership?.current?.isMember
    ) {
      return product.loyaltyDiscount.price
    }
    if (
      product?.loyaltyDiscount &&
      customerInfo?.cards.length > 0 &&
      !product?.loyaltyDiscount?.isSpecial &&
      !product?.loyaltyDiscount?.isClub
    ) {
      return product.loyaltyDiscount.price
    }
    if (product?.discount) {
      return product.discount?.price
    }
    return product?.price || product.stock?.price || 0
  }

  const addToCart = async (prod: CartProduct) => {
    const { inactive, msg, details } = isInactiveAddress(
      selectedAddress,
      deliveryServiceConfig,
      true
    )

    if (inactive) {
      setShowInactiveAddressModal(true, msg, details)
      return
    }
    await addProductToCart(
      cart?._id,
      prod.productId,
      prod._id,
      'ADD',
      lang,
      1,
      user?.sub,
      selectedAddress?.warehouseId,
      customerInfo?.clubMembership?.current?.isMember,
      prod.countStep,
      cartTotals
    )
  }
  const clearCart = async () => {
    try {
      const productsForTagManager = []
      for (const product of products) {
        product.categoryLevels = getCategoryLevels(
          product.categoryId,
          categories,
          lang.toString()
        )
        productsForTagManager.push(product)
      }
      tagManagerUtil.RemoveAllFromCart(productsForTagManager)
      await RequestHelper.catalog.post('carts/truncate', {
        cartId: cartId || cart?._id,
      })
      loadCart(
        lang,
        customerInfo?.clubMembership?.current?.isMember,
        user?.sub,
        selectedAddress?.warehouseId,
        cartId || cart?._id
      )
      logBrazeEventCleanCart(user?.sub)
    } finally {
      setShowModal(false)
    }
  }

  const determinePopularProducts = (isReload = false) => {
    if (cart && cart.products && allPopularProducts.length) {
      const previousCount = popularProducts.length
      const all = [...allPopularProducts]
      const inCartExists = {}
      all.forEach((m) => {
        cart.products.forEach((pr) => {
          if (
            m.productId &&
            pr.prodId &&
            m.productId.toString() == pr.prodId.toString()
          ) {
            inCartExists[m.productId] = true
          }
        })
      })

      const productsToShow = all.filter(
        (pr) => !inCartExists[pr.productId.toString()]
      )

      if (!isReload && previousCount == 4 && productsToShow.length == 3) {
        getPopularProducts(selectedAddress?.warehouseId)
      }
      {
        setPopularProducts(productsToShow)
      }
    }
  }

  useEffect(() => {
    if (!cart?.products.length) {
      setAllPopularProducts([])
      setPopularProducts([])
    } else {
      determinePopularProducts()
    }
  }, [cart?.products])

  useEffect(() => {
    determinePopularProducts()
  }, [allPopularProducts])
  useEffect(() => {
    setItemLoading(true)
    window.setTimeout(async function () {
      setItemLoading(false)
    }, 100)
  }, [cartTotals.totalPrice])

  useEffect(() => {
    function escFunction(event) {
      if (event.key === 'Escape') {
        setOpenCart()
        document.removeEventListener('keydown', escFunction, false)
      }
    }

    if (openCart) {
      document.addEventListener('keydown', escFunction, false)
      getPopularProducts(selectedAddress?.warehouseId)
    }
  }, [openCart])

  return (
    <>
      <Backdrop onClick={() => setOpenCart()} visible={openCart} />
      <div className={cartPopupClassName}>
        <div className={styles.cartPopup__header}>
          <div className={styles.cartPopup__header__title}>
            {translate('cartPopup.title')}
            {cartTotals.quantity ? (
              <>
                &nbsp;({Math.round(cartTotals.quantity * 100) / 100}{' '}
                {translate('cartPopup.productsName')})
              </>
            ) : (
              ''
            )}
          </div>
          <span className={styles.close} onClick={setOpenCart}>
            <FiX size={24} />
          </span>
        </div>

        <>
          <div className={styles.cartPopup__body}>
            <div className={styles.cartPopup__body__inner}>
              {products.length ? (
                <>
                  <div className={styles.clearCart}>
                    <span onClick={() => setShowModal(true)}>
                      {translate('clearCart')}
                    </span>
                  </div>
                  {products.map((p) => (
                    <CartPopupItem
                      key={p.prodId}
                      prodId={p.prodId}
                      cartId={cartId || cart?._id}
                      product={p}
                      categories={categories}
                      user={user}
                    />
                  ))}
                </>
              ) : (
                <>
                  <div className={styles.cartPopup__emptyCartWrapper}>
                    <div className={styles.cartPopup__emptyCart}>
                      <div className={styles.emptyCartIconWrapper}>
                        <span className={styles.countZero}>0</span>
                        <FiShoppingCart size="41px" color="#99AEBB" />
                      </div>
                      <p className={styles.cartIsEmpty}>
                        {translate('cartPopup.cartIsEmpty')}
                      </p>
                      <p className={styles.cartEmpty}>
                        {translate('cartPopup.emptyCart')}
                      </p>
                    </div>
                  </div>
                </>
              )}
              <>
                <div className={styles.cartPopup__popularProducts_container}>
                  {products.length && popularProducts.length ? (
                    <div>
                      <div className={styles.cartPopup__popularProducts_title}>
                        {translate('products.recommended.title')}
                      </div>
                      {popularProducts.map((p) =>
                        p?.stock?.stock ? (
                          <div
                            key={p.prodId}
                            style={{
                              marginTop: 10,
                              marginBottom: 10,
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                            }}
                          >
                            <div
                              style={{ display: 'flex', flexDirection: 'row' }}
                              onClick={() => navigateToDetails(p)}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                }}
                              >
                                {p?.images?.length ? (
                                  <img
                                    height={50}
                                    width={50}
                                    style={{
                                      border:
                                        '1px solid var(--very-light-blue)',
                                      borderRadius: 5,
                                    }}
                                    className={styles.cartPopup__product__image}
                                    src={getProductPrimaryImageUrl(p)}
                                    onError={getBrokenImage}
                                    alt={p.title}
                                  />
                                ) : (
                                  <div
                                    style={{
                                      border:
                                        '1px solid var(--very-light-blue)',
                                      borderRadius: 5,
                                    }}
                                  >
                                    <ProductCardImagePlaceholder
                                      isPopularProduct={true}
                                    />
                                  </div>
                                )}
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginLeft: 15,
                                    paddingTop: 3,
                                  }}
                                >
                                  <span
                                    style={{
                                      color: '#7F7F7F',
                                      fontWeight: 500,
                                      fontSize: 14,
                                      lineHeight: '20px',
                                    }}
                                  >
                                    {p.title}
                                  </span>
                                  <div
                                    style={{
                                      alignSelf: 'start',
                                      letterSpacing: 0.15,
                                      lineHeight: '20px',
                                      display: 'flex',
                                    }}
                                    className={styles.cartPopup__product__total}
                                  >
                                    <div style={{ marginRight: 20 }}>
                                      {generatePrice(p)?.toFixed(2)} <Lari />
                                    </div>
                                    {p.discount?.price ||
                                    (p.loyaltyDiscount?.isClub &&
                                      customerInfo?.clubMembership?.current
                                        ?.isMember) ||
                                    (customerInfo?.cards.length > 0 &&
                                      p.loyaltyDiscount &&
                                      !p.loyaltyDiscount?.isClub &&
                                      !p.loyaltyDiscount?.isSpecial) ? (
                                      <div
                                        className={
                                          styles.cartPopup__product__discount
                                        }
                                      >
                                        <span>{p.stock.price || 0}</span>
                                        &nbsp;
                                        <Lari />
                                      </div>
                                    ) : (
                                      <div
                                        className={
                                          styles.cartPopup__product__discount
                                        }
                                      ></div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <button
                              style={{
                                height: 44,
                                width: 44,
                                borderRadius: 9,
                                border: '1px solid #007A3E',
                                backgroundColor: '#FFFFFF',
                              }}
                              onClick={(e: React.MouseEvent) => {
                                e.stopPropagation()
                                addToCart(p)
                              }}
                            >
                              <FiPlus size="28px" color="#007A3E" />
                            </button>
                          </div>
                        ) : (
                          <></>
                        )
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            </div>
          </div>
        </>

        <div className={styles.cartPopup__footer}>
          <div className={cartBtn} onClick={checkout}>
            <div className={styles.cartPopup__footer_total_price}>
              {itemLoading ? (
                <>
                  <FiLoader
                    className={styles.cartPopup__product__item_loading}
                  />
                </>
              ) : (
                <>
                  {cartTotals?.totalPrice.toFixed(2) || 0} <Lari />
                </>
              )}
            </div>
            <div className={styles.goToBuy}>
              {translate('cartPopup.buy')}
              <svg width="15px" height="14px" viewBox="0 0 15 14">
                <path d="M10.586,5.645 L6.636,1.695 C6.257,1.302 6.262,0.679 6.648,0.293 C7.034,-0.093 7.658,-0.098 8.05,0.281 L13.707,5.938 C13.895,6.125 14.001,6.379 14.001,6.645 C14.001,6.91 13.895,7.165 13.707,7.352 L8.05,13.009 C7.799,13.269 7.427,13.373 7.077,13.281 C6.727,13.19 6.455,12.917 6.363,12.567 C6.272,12.217 6.376,11.846 6.636,11.595 L10.586,7.645 L1,7.645 C0.448,7.645 0,7.197 0,6.645 C0,6.092 0.448,5.645 1,5.645 L10.586,5.645 L10.586,5.645 Z" />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <Modal
        noScrollbarChange={true}
        modalHeaderStyle={{ fontSize: 22, color: '#000000' }}
        visible={showModal}
        title={translate('ecommerce.genericmodal.clearCart.title')}
        onClose={() => setShowModal(false)}
        zIndex={9998}
        content={
          <div className={styles.modalContainer}>
            <div className={styles.modalText}>
              {translate('ecommerce.genericmodal.clearCart.text')}
            </div>
            <div className={styles.modalButtons}>
              <Button
                type="primary"
                text={translate('ecommerce.genericmodal.clearCart.YES')}
                style={{ width: '26%' }}
                onClick={clearCart}
              />
              <Button
                type="secondary"
                text={translate('ecommerce.genericmodal.clearCart.NO')}
                alignIcon
                showBorder={true}
                style={{ width: '26%', marginLeft: '2%' }}
                onClick={() => {
                  setShowModal(false)
                }}
              />
            </div>
          </div>
        }
      />
    </>
  )
}
